
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import SharedLibs from 'wizarding-world-web-shared';
import useTranslation from 'next-translate/useTranslation';
import getT from 'next-translate/getT';
import { removeCartToggleElement } from '@utils/shop';
import { useUserContext } from '@contexts/user-context';
import { AUTH_FLOW_LOGIN_STEP } from '@constants/auth';
import { PROFILE, REGISTER, FORGOT_PASSWORD, MIGRATE, POTTERMORE_VERIFY, REGISTER_VERIFY, LOGIN, } from '@constants/routes';
import { isValidEmail } from '@utils/index';
import { UNDERAGE_QUERY, REGISTER as EXISTING_EMAIL_QUERY } from '@utils/graphQueries';
import { segmentAnonAlias, segmentAnonymousId, segmentIdentify, trackEvent, trackClickEvent, } from '@utils/analytics';
import { getAuthSuccessRedirect, eraseAuthSuccessRedirect } from '@utils/authSuccessRedirect';
import useAuthFlowRouter from '@hooks/useAuthFlowRouter';
import Bugsnag from '@bugsnag/js';
import HeadMeta from '@components/HeadMeta';
import SegmentPage from '@components/SegmentPage';
import BasicInput from '@components/BasicInput';
import Button from '@/src/components/Button';
import AnchorLink from '@components/AnchorLink';
import TogglePasswordInput from '@components/TogglePasswordInput';
import Loaders from '@components/Loaders';
import loaderTypes from '@components/Loaders/loaderTypes';
import LanguagePicker from '@/src/components/LanguagePicker';
import AuthHeader from '../components/AuthHeader';
import { setEmail as setGlobalStateEmail, setPassword as setGlobalStatePassword, setIsUnverified, setIsMigration, } from '../../../globalStates/AuthFlowState';
import bg from '../imgs/bg.jpg';
import bg2x from '../imgs/bg@2x.jpg';
import s from '../Auth.module.scss';
const pageName = 'Login';
const userNotExistError = "We can't find this email and password combination. Have you been Confunded? Try again.";
const Login = () => {
    const { api: wizardingWorldApi, auth: Auth } = SharedLibs.services;
    const router = useRouter();
    const { setRefreshLoggedIn } = useUserContext();
    const [loggingIn, setLoggingIn] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [isPottermoreEmail, setIsPottermoreEmail] = useState(false);
    const [isWWEmail, setIsWWEmail] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('');
    const { t } = useTranslation('common');
    const { isRouting } = useAuthFlowRouter(AUTH_FLOW_LOGIN_STEP);
    useEffect(() => {
        const getEnglishButtonLabel = async () => {
            const defaultT = await getT('en', 'login');
            setButtonLabel(defaultT('sign-up'));
        };
        getEnglishButtonLabel();
    }, []);
    const handleEmailChange = (e) => {
        setEmailError(false);
        setEmailAddress(e.target.value);
    };
    const onEmailBlur = async (event) => {
        const email = event.target.value;
        if (!isValidEmail(email))
            return;
        try {
            const { data } = await wizardingWorldApi().query({
                query: EXISTING_EMAIL_QUERY,
                variables: { email },
                fetchPolicy: 'no-cache'
            });
            const { isPottermore, isWW } = data.register;
            setIsPottermoreEmail(isPottermore);
            setIsWWEmail(isWW);
            setGlobalStateEmail(email);
            if (isPottermore && !isWW) {
                trackEvent('Potermore Email Detected', { workflow: 'Login' });
            }
        }
        catch (err) {
            // istanbul ignore next
            Bugsnag.notify(err);
        }
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setGlobalStatePassword(e.target.value);
    };
    const formHasErrors = () => {
        let hasErrors = false;
        if (!emailAddress.trim() || !isValidEmail(emailAddress)) {
            hasErrors = true;
            setEmailError(true);
        }
        return hasErrors;
    };
    const handleError = async (error) => {
        // istanbul ignore else
        if (error.code === 'UserNotFoundException' || error.code === 'NotAuthorizedException') {
            trackEvent('Login Failed', {
                error_code: error.code,
                error_text: userNotExistError
            });
        }
        else if (isPottermoreEmail && error.code === 'UserNotConfirmedException') {
            setIsUnverified(true);
            router.push(POTTERMORE_VERIFY);
            return;
        } // istanbul ignore next
        else if (error.code === 'UserNotConfirmedException') {
            setIsUnverified(true);
            router.push(REGISTER_VERIFY);
            return;
        }
        setLoggingIn(false);
        setShowError(true);
    };
    const handleVisibilityToggle = (visible) => {
        trackEvent('Password Visibility CTA Clicked', {
            visibility_enabled: !visible,
            location: pageName
        });
    };
    const handleLogin = async (e) => {
        e.preventDefault();
        setShowError(false);
        setEmailError(false);
        if (formHasErrors())
            return;
        setLoggingIn(true);
        trackEvent('Login Submitted', {
            login_type: 'Email',
            location: pageName
        });
        try {
            await Auth.signIn(emailAddress.toLowerCase(), password);
            setRefreshLoggedIn(true);
            const userData = await Auth.currentUserInfo();
            const { data } = await wizardingWorldApi().query({
                query: UNDERAGE_QUERY
            });
            if (data.me.underage) {
                removeCartToggleElement();
            }
            const prevId = segmentAnonymousId();
            segmentIdentify(userData.attributes.wwid, {
                subscription_type: 'Free',
                is_underage: data.me.underage
            });
            segmentAnonAlias(userData.attributes.wwid, prevId);
            trackEvent('Login Successful');
            const redirect = getAuthSuccessRedirect() || PROFILE;
            eraseAuthSuccessRedirect();
            router.push(redirect);
        }
        catch (error) {
            handleError(error);
        }
    };
    if (isRouting)
        return <Loaders type={loaderTypes.timeturner}/>;
    return (<div className={s.root} style={{ '--bg': `url(${bg})`, '--bg2x': `url(${bg2x})` }}>
      <HeadMeta title={pageName} canonicalUrl={LOGIN}/>
      <SegmentPage key="Login" pageName={pageName}/>
      <div className={s.root}>
        <div className={s.header}>
          <AuthHeader pageName={pageName} showBackBtn/>
        </div>
        <div className={s.altHeader}>
          <hr />
          <div className={s.diamonds}/>
          <h1>{t('login:header.login')}</h1>
          <div className={s.diamonds}/>
          <hr />
        </div>
        <div className={s.form}>
          <label className={s.label} htmlFor="emailAddress">
            {t('login:form-fields.email-address')}
          </label>
          <BasicInput name="emailAddress" required autoComplete="on" data-autofocus isError={emailError || showError} onChange={(e) => handleEmailChange(e)} onBlur={(e) => onEmailBlur(e)} data-testid="emailAddressField"/>
          {emailError && (<span className={s.dynamicErrorText}>{t('login:validation-text.valid-email')}</span>)}
          {isPottermoreEmail && !isWWEmail && (<div data-testid="pottermoreText">
              <p className={s.marginReset}>{t('login:pottermore-account')}</p>
              <AnchorLink onClick={() => setIsMigration(true)} href={MIGRATE} title="Sign Up with Pottermore">
                {t('login:signup-pottermore')}
              </AnchorLink>
            </div>)}
          <label className={s.label} htmlFor="password">
            {t('login:form-fields.password')}
          </label>
          <TogglePasswordInput name="password" required iconHide="hide" iconShow="show" iconColor="#ffffff" autoComplete="off" data-autofocus iconClassName={s.icon} data-testid="passwordField" isError={showError} onChange={(e) => handlePasswordChange(e)} onVisibilityToggle={handleVisibilityToggle} onKeyPress={
        // istanbul ignore next
        (e) => {
            if (e.key === 'Enter') {
                handleLogin(e);
            }
        }} errorText={t('login:form-errors.user-not-found')}/>
          <AnchorLink href={FORGOT_PASSWORD} className={s.forgotPassword}>
            {t('login:forgot-password')}
          </AnchorLink>
          <Button label={t('auth:login')} onClick={(e) => handleLogin(e)} loading={loggingIn} disabled={loggingIn} data-testid="loginButton" fullWidth mt="20px"/>
          <div className={s.login}>
            <hr />
            <h3 className={s.center}>{t('login:dont-have-account')}</h3>
            <Button label={t('login:sign-up')} type="secondary" onClick={() => {
            trackClickEvent({
                label: buttonLabel,
                destination_url: REGISTER,
                location: pageName
            });
            router.push(REGISTER);
        }} fullWidth/>
            <LanguagePicker pageName={pageName} marginBottom={30} marginTop={30}/>
          </div>
        </div>
      </div>
    </div>);
};
export default Login;

    async function __Next_Translate__getStaticProps__194cc7959bd__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Auth/Login/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194cc7959bd__ as getStaticProps }
  